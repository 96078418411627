aside {
  height: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  &.fullWidth {
    display: block;
    overflow: auto;
  }
  header {
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 13rem;
      }
      &.login {
        justify-content: flex-start;
        padding: 0.3rem 1rem;
        img {
          max-width: 3rem;
        }
      }
    }
    .vection-logo {
      background-color: $blacklight;
      justify-content: flex-start;
      padding: 1rem;
    }
  }
  .main-content {
    z-index: 99;
    padding: 0 1.1rem;
    @include media-breakpoint-down(sm) {
      padding: 0 2.5rem;
    }
    h1 {
      font-size: 2rem;
      text-align: center;
      color: $white;
      font-weight: 700;
      white-space: nowrap;
      line-height: 1.3;
      margin-bottom: 1.2rem;
      span {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
    .entry-info {
      background-color: rgba(0, 0, 0, 0.7);
      padding: 1.2rem 1.5rem;
      border-radius: 0.5rem;
      ul {
        li {
          color: $white;
          line-height: 2.3;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img {
            max-width: 1.7rem;
            margin-right: 1.3rem;
          }
        }
      }
    }
  }
  .background {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center 11rem;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &.login {
    justify-content: flex-start;
    padding: 0.3rem 1rem;
    img {
      max-width: 3rem;
    }
  }
  &.detail {
    background-color: $primary;
    justify-content: flex-start;
    padding: 0.3rem 1rem;
    img {
      max-width: 3rem;
    }
  }
  .sidebar {
    max-width: 27.735rem;
    z-index: 2;
    height: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
  }
}

.detail {
  .entry-para {
    p {
      color: $white;
      font-size: 0.85rem;
    }
  }
  .how-works {
    .entry-thumb {
      justify-content: center;
      display: flex;
      margin: auto;
      img {
        max-width: 10rem;
      }
    }
    .entry-content {
      h2 {
        font-size: 1.3rem;
        color: $white;
      }
      p {
        color: $white;
        font-size: 0.7rem;
      }
    }
  }
  .entry-useCase {
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 0.6rem;
      color: $white;
    }
    .entry-content {
      .accordion {
        .accordion-item {
          border: 0;
          border-bottom: 1px solid #9f9f9f;
          .accordion-button {
            background-color: $dark;
            color: $white;
            &:focus {
              box-shadow: none;
            }
            &:after {
              display: none;
            }
            &:before {
              content: '';
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid rgba(255, 255, 255, 0.6);
              position: absolute;
              left: 0px;
              top: 1rem;
              z-index: 999;
              transform: rotate(-90deg);
              -webkit-transform: rotate(-90deg);
              transition: all 0.4s ease-in-out;
              -webkit-transition: all 0.4s ease-in-out;
            }
            &:not(.collapsed) {
              &:before {
                content: '';
                transform: rotate(0);
                -webkit-transform: rotate(0);
                transition: all 0.3s ease-in-out;
                -webkit-transition: all 0.3s ease-in-out;
              }
            }
          }
          .accordion-body {
            background-color: $dark;
            color: $white;
            font-size: 0.85rem;
          }
          h2 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.scene-list {
  h1 {
    font-size: 2rem !important;
    text-align: center;
    color: $white;
    font-weight: 700 !important;
    white-space: nowrap;
    line-height: 1.3;
    span {
      font-size: 2rem !important;
      font-weight: 500 !important;
    }
  }
  .test {
    li {
      border: 1px solid $grey;
      padding: 1rem 1.2rem;
      border-radius: 8px;
      label {
        width: 100%;
        text-align: center;
        font-size: 1.4rem;
      }
      .form-check {
        display: flex;
        align-items: center;
      }
    }
  }
  .add-btn {
    max-width: 3rem;
    margin: 0 auto;
    display: block;
  }
}

footer {
  text-align: center;
  margin-top: auto;
  p {
    color: #fff;
  }
  .footer-logo {
    img {
      max-width: 5rem;
    }
  }
  &.login {
    background-color: $secondary;
    height: 3rem;
  }
  &.detail {
    background-color: $primary;
    height: 5rem;
  }
}
