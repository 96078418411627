// $btn-padding-y: 0.4rem;
// $btn-padding-x: 2rem;
// $btn-border-radius: 0.4rem;

// //Accordian

// $accordion-button-icon:         none;
// $accordion-button-active-icon:  none;
// $accordion-button-active-bg: $primary;
// $accordion-button-active-color: #fff;
// $accordion-border-color: $secondary;
// $accordion-button-focus-border-color:     none;
// $accordion-button-focus-box-shadow:       none;

// //form

// $input-border-color:                    $secondary;
// $input-border-radius:                   6px;
// $input-placeholder-color:               $gray-600;
// //$input-btn-focus-box-shadow:            0px 0px 4px 1px $gray-500;
// $input-focus-border-color:              $secondary;

// //Table
// $table-cell-padding-x:        0.3rem;

// //popover
// $popover-border-color: $secondary;
// $popover-body-padding-y: 0.5rem;
// $popover-body-padding-x: 0.5rem;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$btn-padding-y: 0.3rem;
$btn-padding-x: 0.3rem;
$btn-border-radius: 0.8rem;

$form-label-color: $white;
$form-label-font-size: 0.8rem;
$input-border-radius: 1.5rem;
$input-height: 1.6rem;
$input-border-width: 0;
$input-padding-y: 0.4rem;
$form-text-color: $dark;
$input-font-size: 0.8rem;

$accordion-button-padding-y: 0.6rem;
$accordion-body-padding-y: 0.6rem 1rem;
$accordion-border-radius: 0;
