main {
  height: 100vh !important;
}
.scene-list {
  height: 100vh !important;
}
.background01 {
  background-image: url('../images/background.png');
}
.overflow-y-auto {
  overflow: hidden;
  overflow-y: auto;
}
.text-justified {
  text-align: justify;
}
.come-back-link {
  height: 1rem;
  margin-top: auto;
  text-align: center;
}
.custom-sign-page .come-back-link {
  height: 2rem;
  padding-top: 0.3rem;
}
.come-back-link.dashboard {
  display: block;
  overflow: auto;
  height: 3rem;
  color: white;
  background-color: black;
  margin-top: auto;
  text-align: center;
  padding-top: 1rem;
}

footer.custom-sign-footer {
  margin-top: unset;
}
.come-back-link {
  margin-top: 10px;
  height: 0.1rem;
  margin-top: auto;
  font-size: 13px;
  text-align: center;
}
.text-error {
  color: rgb(231, 20, 20);
}
.text-justified {
  text-align: justify;
}

.background-participant {
  padding: 1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 16rem;
  background-size: cover;
  background-position: top;
  background-image: url('../images/background.png');
}
.background-top {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  position: absolute;
  left: 0;
  top: 7rem;
  bottom: 0;
  margin: auto auto;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.participant-content .btn {
  padding: 0.6rem;
  margin-right: 6rem;
  margin-left: 6rem;
  margin-top: 0.6rem;
}

.background-participant h2 {
  font-size: 1.8rem;
  color: #fff;
}
.click-the-bottom {
  font-size: 1.4rem;
}
.dont-have-installed {
  font-size: 2rem;
}
.click-the-bottom span,
.dont-have-installed span,
.background-participant span {
  font-weight: bold;
}
.text-error {
  color: rgb(231, 20, 20);
}
.text-justified {
  text-align: justify;
}
.custom-clickable {
  cursor: pointer;
}
.scene-list-item-div.custom-clickable > * {
  cursor: pointer;
}
.scene-list .not-allowed,
.scene-list .not-allowed > *,
.scene-list .not-allowed:focus {
  opacity: 1;
  cursor: not-allowed;
  pointer-events: all;
  box-shadow: none;
}

.getting-started-info {
  color: #000;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;
  text-align: center;
}
.getting-started-info ul li {
  color: #000;
  line-height: 2.3;
  align-items: center;
  justify-content: flex-start;
}
.launch-frames-link,
.launch-frames-link:hover {
  text-align: center;
  text-decoration: underline;
  color: #0a58ca;
}

.overflow-y-auto {
  overflow: hidden;
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow: hidden;
  overflow-y: scroll;
}

header .login {
  background-color: transparent !important;
}

@media only screen and (max-width: 600px) {
  .participant-content .btn {
    padding: 0.6rem;
    margin-right: 1rem;
    margin-left: 1rem;
    margin-top: 0.6rem;
  }
}
footer {
  padding-top: 10px;
}

/* Scrollbar  */
.custom-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}
.custom-scroll::-webkit-scrollbar:vertical {
  width: 12px;
}
.custom-scroll::-webkit-scrollbar:horizontal {
  height: 12px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #ced4da52;
  border-radius: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #66666673;
}
/* .custom-loader {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
} */
