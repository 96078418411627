$white: #fff;
$dark: #000;
$primary: #0d3c4c;
$secondary: #400131;
$blacklight: #222529;
$grey: #828586;

$theme-colors: (
  'white': $white,
  'dark': $dark,
  'primary': $primary,
  'secondary': $secondary
);

$custom-colors: (
  //"lightyellow" :$lightyellow,
  'blacklight': $blacklight,
  'grey': $grey
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

$base-font: 'Exo', sans-serif;
